import * as React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BreadCrumbs from "../components/breadCrumbs";
import MembershipContent from "../components/membership/template/membershipContent";

const useStyles = makeStyles(({ theme }) => ({
  root: {
    width: "100%",
    marginTop: "4.166vw",
    padding: "0 3.47vw",
    "@media(min-width: 1440px)": {
      padding: "0 50px",
      marginTop: 60,
    },
    "@media(max-width: 767px)": {
      padding: 0,
      marginTop: "4.83vw",
    },
  },
}));

const MembershipMethodicPage = ({ data: { prismicMembershipMethodic } }) => {
  const classes = useStyles();
  const methodicData = prismicMembershipMethodic.data;
  return (
    <Layout>
      <Seo title="Абонемент" />
      <BreadCrumbs
        links={[
          { title: "Абонементы"},
          {
            title: methodicData.name,
            href: `/memberships/${prismicMembershipMethodic.uid}`,
          },
        ]}
      />

      <div className={classes.root}>
        <MembershipContent data={methodicData} type={"methodic"} />
      </div>
    </Layout>
  );
};

/**
 * Страница Абонемента
 * @module src/templates/membership
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 */
export default MembershipMethodicPage;

export const query = graphql`
  query MembershipMethodicPage($uid: String!) {
    prismicMembershipMethodic(uid: { eq: $uid }) {
      data {
        benefits {
          benefit
        }
        name
        price
        tag_age
        tag_category
        tag_type
        images {
          image {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
